@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
  cursor: pointer;
  height: 4.375rem;
  object-fit: cover;
  width: 5.3125rem;
}

.animated.thumbs {
  text-align: center;
}

.carousel.carousel-slider {
  max-height: 550px;
  margin: auto !important;
}

.gallery-item img {
  object-fit: contain;
  max-height: 500px
}